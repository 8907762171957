import React from "react";
import cardimg from "../../assets/images/home-img-2.png";
const SingleProductCard = ({ item }) => {
  const objectToArray = (obj) => {
    const keys = Object.keys(obj);
    const values = Object.values(obj);
    let arr = [];
    keys.forEach((key, i) => {
      let val = values[i];
      let desc = "";
      val.forEach((obj, index) => {
        if (index === val.length - 1) desc += obj.value;
        else desc += (obj.value + ", ");
      })
      let obj = {
        title: key,
        desc: desc
      };
      arr.push(obj);
    })
    return arr;
  }
  console.log(item);
  let adds = objectToArray(item.add_ons);
  return (
    <div className="flex flex-row w-auto cursor-pointer justify-between items-center bg-product border-l-4 border-primary-dark-color rounded-lg p-2 pb-0 my-2">
      <div className="flex flex-row  gap-2 ">
        <div className="w-[80px] overflow-hidden">
          <img
            src={cardimg}
            alt="cardimg"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="font-montserrat flex justify-center flex-col ">
          <div className="font-bold text-xl  ">{item.product_name}</div>
          <div className="font-semibold capitalize text-[10px] text-primary-color">
            {adds.map((addon, i) => <span className="mr-1" key={i}>{addon.desc}{i !== adds.length - 1 && ', '}</span>)}
            {/* {item.add_ons !== {} && Object.entries(item.add_ons).map((addon, index) => {
              let final = addon[0] + ' : ' + addon[1];
              if (index !== (Object.entries(item.add_ons).length - 1)) final = final + ', ';
              return final;
            })} */}
          </div>
        </div>
      </div>
      <div className="font-bold text-lg mb-2 whitespace-nowrap">₹ {item.price}</div>
    </div>
  );
};

export default SingleProductCard;
