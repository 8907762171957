import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import offerImage from '../../assets/images/offer_image.png'

export default function OffersSection() {
    const state = useSelector(state => state);
    const navigate = useNavigate();
    return (
        state.coupons !== null &&
        <div className='flex justify-center align-middle flex-wrap mt-[20px] z-[9]'>
            {
                state.coupons.map((coupon, i) => <div key={i} className='w-[300px] h-[200px] bg-product m-[10px] rounded-[10px] border-primary-color border-[2px] overflow-hidden flex justify-center' onClick={() => {
                    navigate(`/offer-details`, { state: { coupon: coupon } });
                }}>
                    <div className='w-[200px] h-[200px] px-5 py-2 relative'>
                        <p className='text-primary-color font-bold text-xl'>{coupon.title}</p>
                        <p className='text-sm text-dark-color mt-1'>{coupon.description}</p>
                        <p className='absolute bottom-[30px] left-[20px] px-3 py-[2px] bg-primary-dark-color text-primary-light-color rounded-md'>Details</p>
                    </div>
                    <div className='w-[100px] flex justify-center align-middle overflow-hidden border-primary-color border-l-[2px]'>
                        <img src={offerImage} className='h-[100%] max-w-screen-2xl max-h-screen' />
                    </div>
                </div>)
            }

        </div>
    )
}
