import React, { useState, useEffect } from 'react'
import Footer from '../components/common/Footer'
import Header from '../components/common/Header'
import TopBar from '../components/common/TopBar'
import HeadTop from '../components/menu/Headtop'
import LeftSection from '../components/menu/LeftSection'
import RightSection from '../components/menu/RightSection'
import { useSelector, useDispatch } from 'react-redux';
import { searchProduct } from '../actions';

const Menu = () => {
  const state = useSelector(state => state);
  const [key, setKey] = useState(0);
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  

  useEffect(() => {
    setKey(key => key+1);
  }, [state.query])

  useEffect(() => {
    if (state.currentBranch !== null)
      dispatch(searchProduct({ branch: state.currentBranch, type: "search", search: search }));
  }, [search, state.currentBranch, dispatch]);

  return (
    <>
      <TopBar />
      <Header />
      <HeadTop />
      <div className='md:container mx-auto px-6 mt-[30px]'>
        <div className='p-2 relative border-radius-5 mb-2'>
            <input type="search" className='w-full bg-white border-radius-5  text-black p-2 font-roboto focus:outline-none pr-6 text-center' placeholder='Search Items' onChange={(e) => setSearch(e.target.value)} value={search} style={{opacity: '0.9'}} />
        </div>
        <div className='flex flex-wrap md:flex-nowrap justify-start gap-6 h-[100%]'>
          <LeftSection />
          <RightSection key={key} />
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Menu