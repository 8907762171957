import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Menu, Dialog, Transition } from "@headlessui/react";
import { useDispatch, useSelector } from 'react-redux';
import makePayment from "../../fntendpaytm";
import { getPromoCode, setPromoCode } from "../../actions";
import PromoCode from './PromoCode';
import {
    XIcon
} from "@heroicons/react/outline";
const AddCredit = ({ isAddMoneyOpen, setIsAddMoneyOpen }) => {
    const inputRef = useRef(null);
    const dispatch = useDispatch();
    const state = useSelector(state => state);

    const [addingMoney, setAddingMoney] = useState('');
    const [disabled, setDisabled] = useState(true);



    useEffect(() => {
        if (isAddMoneyOpen && inputRef.current !== null) {
            inputRef.current.focus();
        }
    }, [isAddMoneyOpen, inputRef.current])

    const closeAddMoneyModal = () => {
        setIsAddMoneyOpen(false);
        setAddingMoney();
        dispatch(setPromoCode([]));
    }

    const pay = () => {
        const body = {
            amount: Number(addingMoney),
            branch_id: state.currentBranch.branch_id,
            branch_name: state.currentBranch.branch_name,
            promo_code_id: state.setPromoCode.promo_code_id
        };
        const token = state.token;
        //console.log(body)
        makePayment(body, token);
    }

    useEffect(() => {
        if (addingMoney > 0) setDisabled(false);
        else setDisabled(true);
    }, [addingMoney])


    useEffect(() => {
        if (state.token !== null) dispatch(getPromoCode(state.token));
    }, [state.token])

    return (
        <Transition appear show={isAddMoneyOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeAddMoneyModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto text-dark-color border-radius-5">
                    <div className="flex min-h-full items-center justify-center text-center border-radius-10">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-[300px] transform overflow-hidden bg-product transition-all border-radius-10 p-5">
                                <div className="text-2xl uppercase font-bold">Add Credit</div>
                                <div className="my-1">
                                    <input type="number" min="0" pattern="[0-9]" oninput="validity.valid||(value='');" placeholder="Enter Credit Amount" className="bg-primary-light-color text-primary-dark-color border-[1.5px] border-primary-dark-color p-2 outline-none  border-radius-5 text-sm w-full" onChange={(e) => setAddingMoney(e.target.value)} value={addingMoney} ref={inputRef} />
                                </div>
                                {state.setPromoCode.promo_code_id &&
                                    <div className="flex justify-between items-center bg-green-500/50 p-2 rounded-lg mb-3">
                                        <div>
                                            <h3 className="font-semibold text-md text-left mb-0 leading-tight text--green-500">{state.setPromoCode.title}</h3>
                                            <h3 className="opacity-50 text-xs text-left text--green-500">Min. Amount ₹{state.setPromoCode.min_amount}</h3>
                                            <h3 className="opacity-50 text-xs text-left text--green-500">Extra Amount ₹{state.setPromoCode.extra_amount}</h3>
                                        </div>
                                        <button className="border-radius-5 text-[14px] font-semibold p-[2px] cursor-pointer bg-green-500/60 text--green-500" onClick={() => { dispatch(setPromoCode('')) }}><XIcon className="h-5 w-5" /></button>
                                    </div>
                                }
                                <div className="mb-5 flex gap-3 mt-2">
                                    <button className="w-1/3 uppercase bg-grey-200 py-2 text-grey-800 font-semibold text-sm border-radius-5 hover:bg-grey-300 text-center" onClick={closeAddMoneyModal}>Close</button>
                                    <button className={`w-2/3 font-semibold border-radius-5 uppercase text-sm px-4 py-2 ${disabled ? `button-primary ${state.theme === 'dark' ? 'text-grey-300' : 'text-grey-700'} cursor-not-allowed` : 'bg-primary-dark-color text-light-color cursor-pointer'}`} disabled={disabled} onClick={pay}>Add Credit</button>
                                </div>
                                <div className="mb-2 flex flex-col gap-2 h-[200px] overflow-auto">
                                    {state.promoCode.length > 0 ?
                                        state.promoCode.map((item, index) =>
                                            <PromoCode item={item} key={index} addingMoney={addingMoney} />
                                        )
                                        : <h3 className='text-xl'>No Promo Code Found</h3>
                                    }
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}

export default AddCredit