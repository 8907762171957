import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom';
import offerImage from '../../assets/images/offer_image.png'
import { currentCoupon } from '../../actions';
import axios from 'axios';
import Product from '../home/sub_comp/Product';
import { useAlert } from 'react-alert';
const { REACT_APP_API_ENDPOINT } = process.env;

export default function OfferSection() {
    const state = useSelector(state => state);
    const navigate = useNavigate();
    const location = useLocation();
    const [coupon, setCoupon] = useState(null);
    const dispatch = useDispatch();
    const [products, setProducts] = useState(null);
    const [renderProducts, setRenderProducts] = useState(null);
    const alert = useAlert();

    const applyCoupon = () => {
        let amount = 0;
        state.cartItems.forEach((i) => {
            if (i.coupon === null) amount += i.amount;
        });
        if (amount < coupon.min_cart_value) alert.error('Cart Value is less than required !');
        else {
            dispatch(currentCoupon(coupon));
            if (state.cartItems.length > 0) navigate('/cart');
            else navigate('/menu');
        }
    }

    useEffect(() => {
        if (location) {
            setCoupon(location.state.coupon);
        }
    }, [location])

    useEffect(() => {
        if (coupon !== null) {
            if (coupon.discount_type === 'product' || coupon.discount_type === 'addon') {
                const url = `${REACT_APP_API_ENDPOINT}/apply_coupon`;
                const body = {
                    branch_id: state.currentBranch.branch_id,
                    all_coupons_id: coupon.all_coupons_id,
                    discount_type: coupon.discount_type,
                    customer_no: state.customerDetails.mobile_no,
                    customer_id: state.customerDetails.customer_id
                }
                axios.post(url, body, {
                    headers: {
                        token: state.token
                    }
                }).then((response) => {
                    // console.log(response);
                    const products = response.data.allproducts;
                    setProducts(products);
                }).catch(err => console.log(err));
            }
            else {
                setProducts(null);
            }
        }
    }, [coupon])

    useEffect(() => {
        if (products !== null) {
            const p = [];
            products.forEach((pr) => {
                const pro = {
                    product_id: pr.products[0].product_id,
                    product_list_id: pr.products[0].product_list_id,
                    branch_id: pr.products[0].branch_id,
                    category_id: pr.products[0].category_id,
                    status: pr.products[0].status,
                    price: pr.products[0].price,
                    items_available: pr.products[0].items_available,
                    no_of_order: pr.products[0].no_of_order,
                    product_list: {
                        product_list_id: pr.product_list_id,
                        product_name: pr.product_name,
                        sku: pr.sku,
                        description: pr.description,
                        card_img: pr.card_img,
                        price: pr.price,
                        status: pr.status,
                        prepare_time: pr.prepare_time,
                        product_type: pr.product_type,
                        food_type: pr.food_type,
                        ID: 0,
                        no_of_order: 0,
                        createdAt: pr.createdAt,
                        updatedAt: pr.updatedAt,
                        per_product_add_ons: []
                    }
                };
                let ppa = [];
                pr.per_product_add_ons.forEach((x) => {
                    let options = [];
                    if (coupon.discount_type === 'addon') {
                        x.add_on.coupon_addons.forEach(ca => {
                            let option = {
                                add_on_option_id: ca.add_on_option_id,
                                add_ons_id: ca.add_ons_id,
                                title: ca.title,
                                price: ca.price,
                                sku: ca.sku,
                                order: ca.order,

                            };
                            options.push(option);
                        })
                    }
                    else if (coupon.discount_type === 'product') {
                        x.add_on.add_on_options.forEach(ca => {
                            let option = {
                                add_on_option_id: ca.add_on_option_id,
                                add_ons_id: ca.add_ons_id,
                                title: ca.title,
                                price: ca.price,
                                sku: ca.sku,
                                order: ca.order,

                            };
                            options.push(option);
                        })
                    }
                    let singleppa = {
                        per_product_add_ons_id: x.per_product_add_ons_id,
                        product_list_id: x.product_list_id,
                        add_ons_id: x.add_ons_id,
                        required: x.required,
                        order: x.order,
                        add_on: {
                            add_ons_id: x.add_on.add_ons_id,
                            title: x.add_on.title,
                            add_on_type: x.add_on.add_on_type,
                            order: x.add_on.order,
                            add_on_options: options
                        }
                    }
                    ppa.push(singleppa);
                })
                pro.product_list.per_product_add_ons = ppa;
                p.push(pro);
            })
            setRenderProducts(p);
        }
    }, [products])
    return (
        coupon !== null &&
        <div className='mt-[20px] w-[100%]'>
            {(coupon.discount_type === 'percentage' || coupon.discount_type === 'flat') &&
                <div className='flex justify-center items-center flex-wrap'>
                    <div className='w-[300px] h-[200px] overflow-hidden flex justify-center items-center m-2 rounded-md border-primary-dark-color border-[2px]'>
                        <img src={offerImage} className='w-[100%]' />
                    </div>
                    <div className='w-[300px] h-[200px] m-2 bg-product rounded-md border-primary-dark-color border-[2px] p-2'>
                        <p className='text-xl mb-2 font-bold text-primary-dark-color'>{coupon.title}</p>
                        <p className='text-md font-bold'>{coupon.descriprion}</p>
                        <p className='text-md font-bold'>Coupon Code : {coupon.code}</p>
                        <p className='text-md font-bold'>Discount : {coupon.discount_type === 'percentage' ? `${coupon.discount_value}% off` : `Rs. ${coupon.discount_value} off FLAT`} {coupon.max_discount_valid && `upto Rs. ${coupon.max_discount_value}`}</p>
                        <p className='text-md font-bold'>Min Cart: Rs. {coupon.min_cart_value}</p>
                        <p onClick={() => {
                                applyCoupon();
                        }} className='px-[15px] py-[5px] bg-primary-dark-color text-primary-light-color font-bold w-fit rounded-md mt-2 text-sm cursor-pointer'>Apply Coupon</p>
                    </div>
                </div>
            }
            {
                renderProducts !== null &&
                <>
                    <p className='text-primary-dark-color font-bold text-xl my-3'>Select a Product</p>
                    <div className='grid lg:grid-cols-5 md:grid-cols-3 sm:grid-cols-2 xs:grid-cols-1 gap-6 px-4'>
                        {renderProducts.map((product, index) => <Product key={index} product={product} coupon={{ usedCoupon: true, coupon: coupon }} />)}
                    </div>
                </>
            }
        </div>
    )
}
