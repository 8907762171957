import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from 'react-redux';
import DatePicker from 'react-date-picker';
import axios from "axios";
import { useAlert } from "react-alert";
import { setCustomerDetails } from "../../actions";
import { Menu, Dialog, Transition } from "@headlessui/react";
import { IoWalletOutline } from "react-icons/io5";
import makePayment from "../../fntendpaytm";
import Logo from "../../assets/images/heebeeIcon.svg"
import GeneralCard from '../../assets/images/card/general.png'
import SilverCard from '../../assets/images/card/silver.png'
import GoldCard from '../../assets/images/card/gold.png'
import PlatinumCard from '../../assets/images/card/platinum.png'
import Moment from "react-moment";
import MostOrderItems from "./MostOrderItems";

const { REACT_APP_API_ENDPOINT } = process.env;

const MyAccountRightSection = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const state = useSelector(state => state);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [date, setDate] = useState("");
  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [bgColor, setBgColor] = useState("-blue-200");
  const [amountToBeAdded, setAmountToBeAdded] = useState(0);
  const [displayRole, setDisplayRole] = useState(null);

  const getCurrentRole = () => {
    if (state.customerDetails !== null && state.customerRoles !== null) {
      if (state.customerDetails.customer_type === 'Silver') setDisplayRole(state.customerRoles[0]);
      else if (state.customerDetails.customer_type === 'Gold') setDisplayRole(state.customerRoles[1]);
      else if (state.customerDetails.customer_type === 'Platinum') setDisplayRole(state.customerRoles[2]);
      else setDisplayRole('General');
    }
  }

  useEffect(() => {
    getCurrentRole();
  }, [state.customerDetails, state.customerRoles])

  useEffect(() => {
    if (state.customerDetails !== null) {
      let cd = state.customerDetails;
      setFirstName(cd.first_name);
      if (cd.last_name !== null) setLastName(cd.last_name);
      setEmail(cd.email);
      if (cd.date_of_birth !== null) {
        let dob = cd.date_of_birth;
        let d = dob.split("-");
        d = new Date(d[0], d[1] - 1, d[2]);
        setDate(d);
      }
      switch (cd.customer_role.customer_type) {
        case "Gold": setBgColor("-gold"); break;
        case "Silver": setBgColor("-silver"); break;
        case "Platinum": setBgColor("-platinum"); break;
        case "General": setBgColor("-general"); break;
        default: setBgColor("-blue-500");
      }
    }
  }, [state.customerDetails])

  const pay = () => {
    if (isNaN(amountToBeAdded) === false && amountToBeAdded > 0) {
      const body = {
        amount: Number(amountToBeAdded),
        branch_id: state.currentBranch.branch_id,
        branch_name: state.currentBranch.branch_name
      };
      const token = state.token;
      makePayment(body, token);
    }
    else {
      alert.error("Invalid Amount !")
    }
  }

  const [isAddMoneyOpen, setIsAddMoneyOpen] = useState(false);
  const [addingMoney, setAddingMoney] = useState(0);
  const [disabled, setDisabled] = useState(true);

  const closeAddMoneyModal = () => {
    setIsAddMoneyOpen(false);
    setAddingMoney(0);
  }

  useEffect(() => {
    if (addingMoney > 0) setDisabled(false);
    else setDisabled(true);
  }, [addingMoney])


  const saveDetails = () => {
    let user = {
      mobile_no: Number(state.customerDetails.mobile_no),
      first_name: firstName,
      last_name: lastName,
      date_of_birth: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
      email: email
    };

    axios.post(`${REACT_APP_API_ENDPOINT}/edit_profile`, user, {
      headers: {
        token: state.token
      }
    })
      .then(res => {
        if (res.data.status === "success") {
          alert.success("Profile has been updated successfully");
          dispatch(setCustomerDetails(state.token));
        }
        else
          alert.error(res.data.msg)
      })
      .catch(() => alert.error("error occured"));
  }
  const changePassword = () => {
    if (newPassword === "" || confirmPassword === "") alert.error("Passsords can't be empty !");
    else if (newPassword === confirmPassword) {
      let user = {
        mobile_no: Number(state.customerDetails.mobile_no),
        password: newPassword
      };
      axios.post(`${REACT_APP_API_ENDPOINT}/change_password`, user, {
        headers: {
          token: state.token
        }
      })
        .then(res => {
          if (res.data.status === "success") {
            alert.success("Password has been updated successfully");
            setNewPassword("");
            setConfirmPassword("");
            dispatch(setCustomerDetails(state.token));
          }
          else
            alert.error(res.data.msg)
        })
        .catch(() => alert.error("error occured"));
    }
    else {
      alert.error("Passsords don't match !")
    }
  }
  const changeDate = (e) => {
    setDate(e);
  }


  const [customer_upgd, setCU] = useState(0);
  useEffect(() => {
    if (state.customerDetails !== null) {
      const cu = (state.customerDetails.memb_amount * 100) / (state.customerDetails.memb_upg_amount + state.customerDetails.memb_amount);
      setCU(cu);
    }
  }, [state.customerDetails])

  var today = new Date();

  const valid_date = new Date(new Date().setDate(today.getDate() + (state.customerDetails !== null && state.customerDetails.memb_days_left)));
  // console.log(today)
  // console.log(valid_date);

  return (
    <>

      {/* <div className="basis-full mt-2 md:mt-0 md:basis-5/6 px-2 md:px-2 md:pr-2 right-section">
        <div className="px-4">
          <div className="text-primary-dark-color text-2xl font-bold tracking-wide mb-8 text-center uppercase">Membership & Wallet</div> */}
      {/* <div className="flex justify-around items-stretch my-2 flex-wrap">
            <div className={`bg${bgColor} w-full md:w-[22%] text-black rounded-lg flex flex-col justify-center`}>
              <div className={`font-bold px-3 py-1`}>Account Type : {state.customerDetails !== null && state.customerDetails.customer_type}</div>
              <div className={`font-bold px-3 py-1`}>Days Left : {state.customerDetails !== null && state.customerDetails.memb_days_left}</div>
              {state.customerDetails !== null && state.customerDetails.memb_reduce_amount !== null && <div className={`font-bold px-3 py-1`}>Reduce Amount : ₹{(state.customerDetails.memb_reduce_amount).toFixed(2)}</div>}
            </div>
            <div className="w-full md:w-[21%] bg-primary-dark-color flex justify-center flex-col items-center rounded-lg text-light-color">
              <div className="flex justify-center items-center text-xl font-bold mb-2">Wallet Balance <IoWalletOutline className="w-6 h-6 ml-3" /></div>
              <div className="text-xl font-bold">₹{state.customerDetails?.wallet_balance}</div>
            </div>
            <div className={`bg-primary-dark-color w-full md:w-[55%] text-light-color rounded-lg flex justify-around items-center px-6 py-2`}>
              <div className="w-full flex-shrink mr-4">
                <div className="text-xl font-bold">Add Money to Wallet</div>
                <input type="number" className="outline-none rounded-md p-2 w-full mb-2 mt-1 text-black" value={amountToBeAdded} onChange={(e) => setAmountToBeAdded(e.target.value)} />
                <div className="flex justify-evenly">
                  <div className="bg-black text-white rounded-md px-3 mx-1 cursor-pointer" onClick={() => setAmountToBeAdded(a => a + 50)}>50</div>
                  <div className="bg-black text-white rounded-md px-3 mx-1 cursor-pointer" onClick={() => setAmountToBeAdded(a => a + 100)}>100</div>
                  <div className="bg-black text-white rounded-md px-3 mx-1 cursor-pointer" onClick={() => setAmountToBeAdded(a => a + 500)}>500</div>
                  <div className="bg-black text-white rounded-md px-3 mx-1 cursor-pointer" onClick={() => setAmountToBeAdded(a => a + 1000)}>1000</div>
                  <div className="bg-black text-white rounded-md px-3 mx-1 cursor-pointer" onClick={() => setAmountToBeAdded(a => a + 2000)}>2000</div>
                </div>
              </div>
              <div className="whitespace-nowrap bg-white text-black px-3 py-2 rounded-lg border-2 border-black cursor-pointer" onClick={() => pay()}>Proceed to Pay</div>
            </div>
          </div> */}

      {/* <div className=" flex flex-wrap justify-around items-center gap-4">
            <div className={`bg${bgColor} w-full  md:w-2/5 p-4 rounded-3xl `}>
              <div className="flex justify-between items-center mb-16">
                <h4 className="text-md font-semibold uppercase text-black/60">Membership Details</h4>
                <h4 className={`text${bgColor} text-xl px-2 py-1 font-bold uppercase `}>{state.customerDetails !== null && state.customerDetails.customer_type}</h4>
              </div>
              <div className="flex justify-between items-center bg-white/40 p-2 rounded-xl mb-2">
                <h4 className="text-sm font-semibold text-grey-900">Left {state.customerDetails !== null && state.customerDetails.memb_days_left}days</h4>
                <h4 className="text-sm font-semibold text-grey-900">{state.customerDetails !== null && state.customerDetails.memb_upg_amount === 'null' ? `Upgrade Amount ₹${state.customerDetails.memb_upg_amount}` : ''}</h4>
              </div>
              <div className="flex justify-between items-center bg-white/40 p-2 rounded-xl">
                <h4 className="text-sm font-semibold text-grey-900">Retaining Amount ₹{state.customerDetails !== null && (state.customerDetails.memb_reduce_amount).toFixed(2)}</h4>
                <h4 className="text-sm font-semibold text-grey-900">
                  {state.customerDetails !== null && state.customerDetails.memb_upg_categ === 'null' ? `Upgrade to ${state.customerDetails.memb_upg_categ}` : ''}</h4>
              </div>
            </div>
            <div className="w-full md:w-2/5 p-4 rounded-3xl bg-wallet">
              <div className="flex justify-between items-start mb-12">
                <h4 className="text-md font-semibold uppercase text-black/60">Wallet Details</h4>
                <img src={Logo} alt="HeeBee" className="h-[60px]" />
              </div>
              <div className="flex justify-between items-center mb-0">
                <h4 className="text-xl font-semibold text-white/60 uppercase">{state.customerDetails?.first_name} {state.customerDetails?.last_name}</h4>
              </div>
              <div className="flex justify-between items-center">
                <h4 className="text-4xl font-semibold text-black/30 uppercase">₹ {state.customerDetails?.wallet_balance}</h4>
              </div>
            </div>
          </div>

        </div>
      </div>



      <Transition appear show={isAddMoneyOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeAddMoneyModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto text-dark-color border-radius-5">
            <div className="flex min-h-full items-center justify-center text-center border-radius-5">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-fit transform overflow-hidden bg-product transition-all border-radius-5 px-10 py-2">
                  <div className="text-2xl uppercase font-bold">Add Credit</div>
                  <div className="my-5">
                    <input type="number" className="text-black p-1 outline-none border-2 border-grey-400 rounded-md text-sm" onChange={(e) => setAddingMoney(e.target.value)} value={addingMoney} />
                  </div>
                  <div className="mb-[10px]">
                    <button className="rounded-sm uppercase mx-2 bg-grey-300 px-2 py-1 text-grey-800 font-semibold text-sm" onClick={closeAddMoneyModal}>Close</button>
                    <button className={`rounded-sm uppercase mx-2 text-sm px-2 py-1 ${disabled ? `bg-honey ${state.theme === 'dark' ? 'text-grey-300' : 'text-grey-700'} cursor-not-allowed` : 'bg-primary-dark-color text-light-color cursor-pointer'}`}>Add Credit</button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition> */}
      <div className="bg-primary-dark-color rounded-2xl w-full md:max-w-[1000px] mb-4">
        <div className="flex flex-col md:flex-row justify-between items-start gap-2 p-2">
          <div className="w-full md:w-1/2">
            {
              state.customerDetails !== null &&
              <div className="w-full relative mb-2 cursor-pointer" onClick={() => getCurrentRole()}>
                <img src={(state.customerDetails !== null && state.customerDetails.customer_type) === 'General' ? GeneralCard : (state.customerDetails !== null && state.customerDetails.customer_type) === 'Silver' ? SilverCard : (state.customerDetails !== null && state.customerDetails.customer_type) === 'Gold' ? GoldCard : (state.customerDetails !== null && state.customerDetails.customer_type) === 'Platinum' ? PlatinumCard : ''} className="w-full" />
                  <h4 className="absolute top-[20px] sm:top-[30px] md:top-[20px] right-[20px] sm:right-[30px] md:right-[20px] font-semibold text-xl mbtb:text-2xl sm:text-4xl md:text-2xl text-[#ffd490]">
                    {state.customerDetails.customer_type}
                  </h4>
                  <h4 className="absolute bottom-[15px] mbtb:bottom-[30px] sm:bottom-[40px] md:bottom-[25px] left-[50%] translate-x-[-50%] font-semibold text-xl mbtb:text-2xl sm:text-4xl md:text-2xl text-[#ffd490]">{state.customerDetails?.first_name}</h4>
                </div>
            }
            <div className="flex justify-between items-center gap-2 px-1">
              <div className="w-full cursor-pointer" onClick={() => setDisplayRole(state.customerRoles[0])}>
                <img src={SilverCard} className="w-full" />
              </div>
              <div className="w-full cursor-pointer" onClick={() => setDisplayRole(state.customerRoles[1])}>
                <img src={GoldCard} className="w-full" />
              </div>
              <div className="w-full cursor-pointer" onClick={() => setDisplayRole(state.customerRoles[2])}>
                <img src={PlatinumCard} className="w-full" />
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 p-1">
            {displayRole !== null &&
              <div className="w-full md:aspect-[1.54] bg-primary-light-color rounded-3xl p-4 mb-3">
                <h4 className="lg:text-3xl md:text-2xl sm:text-3xl text-2xl font-bold text-primary-dark-color">{displayRole.customer_type}</h4>
                {
                  displayRole === 'General' ? <p className="lg:text-xl md:text-lg sm:text-xl text-base mt-2">Upgrade to Silver for membership discount.</p> :
                    <>
                      <p className="lg:text-xl md:text-base sm:text-xl text-base mt-2">After the purchase of ₹ {displayRole.min_purchase}, Upgrade to {displayRole.customer_type} category and get following benefits:</p>
                      <div className="sm:mt-2 mt-4 flex justify-between">
                        <p className="lg:text-xl md:text-base sm:text-xl text-base">Coffee Discount:</p>
                        <p className="lg:text-xl md:text-base sm:text-xl text-base">{displayRole.barista_disc_percent}%</p>
                      </div>
                      {
                        displayRole.kitchen_disc_perc !== 0 &&
                        <div className="sm:mt-2 mt-4 flex justify-between">
                          <p className="lg:text-xl md:text-base sm:text-xl text-base">Food Discount:</p>
                          <p className="lg:text-xl md:text-base sm:text-xl text-base">{displayRole.kitchen_disc_perc}%</p>
                        </div>
                      }
                    </>
                }
              </div>
            }
            <div className="flex flex-col justify-center items-center lg:gap-2 md:gap-1 gap-2 px-1">
              {/* <div className="bg-primary-light-color px-2 lg:py-2 py-1 rounded-2xl w-full flex justify-between items-center">
                <div className="w-[100%] flex-shrink-1 bg-primary-light-color border-2 border-primary-dark-color rounded-full lg:h-6 md:h-4 h-5 p-0.5">
                  <div className={`bg-primary-dark-color rounded-full lg:h-4 md:h-2 h-3`} style={{ width: `${customer_upgd}%` }} ></div>
                </div>
                <h4 className="lg:text-2xl md:text-lg text-xl font-semibold text-primary-dark-color ml-6">{customer_upgd.toFixed(2)}%</h4>
              </div> */}
              <div className="px-3 lg:py-1 rounded-2xl w-full flex justify-between items-center">
                <h4 className="lg:text-2xl md:text-lg text-xl font-bold text-primary-light-color">Days Left</h4>
                <h4 className="lg:text-2xl md:text-lg text-xl font-semibold text-primary-light-color">{state.customerDetails !== null && (state.customerDetails.memb_days_left)}</h4>
              </div>
              <div className="px-3 lg:py-1 rounded-2xl w-full flex justify-between items-center">
                <h4 className="lg:text-2xl md:text-lg text-xl font-bold text-primary-light-color">Retaining Amount</h4>
                <h4 className="lg:text-2xl md:text-lg text-xl font-semibold text-primary-light-color">₹{state.customerDetails !== null && (state.customerDetails.memb_reduce_amount).toFixed(2)}</h4>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div className="w-full md:max-w-[1000px] border-2 border-primary-dark-color rounded-2xl p-4 flex justify-between items-center mb-4">
        <div className="w-1/2">
          <h4 className="sm:text-3xl text-xl font-semibold text-primary-dark-color">Wallet Details</h4>
          <h4 className="sm:text-xl text-base font-semibold text-primary-dark-color">{state.customerDetails?.first_name}</h4>
        </div>
        <div className="w-1/2 text-right">
          <h4 className="sm:text-4xl text-2xl font-semibold text-primary-dark-color">₹{state.customerDetails?.wallet_balance}</h4>
        </div>
      </div>

      <div className="w-full md:max-w-[1000px] mb-4">
        {/* <div className='grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 xs:grid-cols-1 gap-3 pr-2'> */}
        <MostOrderItems />
        {/*  </div> */}
      </div>
    </>
  );
};

export default MyAccountRightSection;
