import React from "react";
import cardimg from "../../assets/images/home-img-2.png";
import { ChevronRightIcon } from "@heroicons/react/outline";
import { useNavigate } from "react-router";
import Moment from 'react-moment';

const OrderCard = ({ order }) => {
  const navigate = useNavigate();
  let bg;
  if (order.status === "Preparing") {
    bg = "-blue-500";
  }
  else if (order.status === "Completed") {
    bg = "-green-500";
  }
  else if (order.status === "Cancelled") {
    bg = "-red-500";
  }
  return (
    <div
      className={`w-full md:max-w-[1000px] cursor-pointer bg-product rounded-l-lg p-2 my-2 border-radius-10`}
      onClick={() => {
        navigate(`/my-orders/${order.order_id}`);
      }}
    >
      <div className="flex flex-row justify-start items-center relative border-b-[1px] border-primary-dark-color px-2 py-[5px] border-dashed">
        <img src={cardimg} className='w-[40px] h-[40px] mr-[20px]' />
        <p className="text-dark-color font-bold mb-[3px] text-sm sm:text-base">{order.order_id}</p>
        <p className="text-primary-dark-color font-bold text-xs absolute right-[5px] bottom-[5px]">View Details</p>
      </div>
      <div className="border-b-[1px] border-primary-dark-color px-2 py-[8px] border-dashed">
        <div className=" flex flex-row items-center">
          <div className="w-[12px] h-[12px] p-[2px] border-[1.5px] mr-[10px]" style={{ borderColor: order.order_items[0].food_type === 'Veg' ? '#26cb26' : 'red' }}>
            <div className="w-[100%] h-full rounded-full" style={{ backgroundColor: order.order_items[0].food_type === 'Veg' ? '#26cb26' : 'red' }}></div>
          </div>
          <p className="text-dark-color text-xs mr-[10px]">{order.order_items[0].quantity} x</p>
          <p className="text-dark-color text-xs">{order.order_items[0].product_name}</p>
        </div>
        {
          (order.order_items.length > 1) &&
          <p className="text-xs mt-1">and {order.order_items.length - 1} {(order.order_items.length > 2) ? 'items' : 'item'} more</p>
        }
      </div>
      <div className="border-b-[1px] border-primary-dark-color px-2 py-[8px] border-dashed flex flex-row justify-between items-center">
        <p className="text-xs mt-1"><Moment format="DD-MM-YYYY hh:mm A">{order.createdAt}</Moment></p>
        <p className="text-xs mt-1">Rs. {order.paid_price}</p>

      </div>
      {/* <div className="flex flex-row items-center">
        <div className="flex-col space-b-1 px-1">
          <div className="font-montserrat text-sm mbtb:text-xl font-bold ">{order.order_id}</div>
          <div className="text-[10px] mbtb:text-xs text-grey-600">Ordered on : <Moment format="DD-MM-YYYY hh:mm A">{order.createdAt}</Moment></div>
          <div className={`bg${bg} text-white font-medium uppercase w-fit px-[5px] py-0 text-[10px] mbtb:text-xs`}>{order.status}</div>
        </div>
      </div>
      <div>
        <ChevronRightIcon className="w-8 h-8 mb-2" />
      </div> */}
    </div>
  );
};

export default OrderCard;
