import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Dialog, Transition } from '@headlessui/react'
import { useNavigate } from "react-router";
import Logo from '../../assets/images/logo.png'
import { currentCoupon, deleteCartItem, fetchAllOrders, setCartItems, setCustomerDetails } from "../../actions";
import axios from "axios";
import { useAlert } from "react-alert";
import makePayment from "../../fntendpaytm";
import checkoutPaytm from "../../checkoutPaytm";
const { REACT_APP_API_ENDPOINT } = process.env;

const CheckoutPageRightSection = ({ setLoading }) => {
  const state = useSelector(state => state);
  const [paymentMethod, setPaymentMethod] = useState("wallet")
  const alert = useAlert();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [amount, setAmount] = useState(0);
  const [CGST, setCGST] = useState(0);
  const [SGST, setSGST] = useState(0);
  const [moreDiscount, setMoreDiscount] = useState(0);
  const [member_discount, setMD] = useState(0);
  const [disc_coupon, setDisc_coupon] = useState(null);
  const [total, setTotal] = useState(0);
  let [isModalOpen, setIsModalOpen] = useState(false);
  function closeDiscountModal() {
    setIsModalOpen(false);
  }

  function openDiscountModal() {
    setIsModalOpen(true)
  }
  useEffect(() => {
    let sum = 0;
    state.cartItems.forEach(item => sum = sum + item.amount);
    setAmount(sum.toFixed(2));
  }, [state.cartItems]);

  useEffect(() => {
    let tax = ((amount - member_discount - moreDiscount) * 0.05);
    let x = (tax / 2).toFixed(2);
    setCGST(x);
    setSGST(x);
  }, [amount, moreDiscount, member_discount]);

  useEffect(() => {
    setTotal((Number(amount) - Number(member_discount) - Number(moreDiscount) + Number(CGST) + Number(SGST)).toFixed(2));
  }, [amount, member_discount, moreDiscount, SGST, CGST]);

  // const applyDisocunt = (type, value, coupon) => {
  //   if (type === "percent") {
  //     setMoreDiscount((value / 100 * amount).toFixed(2));
  //   }
  //   else setMoreDiscount((value));
  //   setDisc_coupon(coupon);
  //   closeDiscountModal();
  // }

  const deleteCoupon = () => {
    if (state.currentCoupon.discount_type === "product" || state.currentCoupon.discount_type === "addon") {
      state.cartItems.forEach((i, index) => {
        if (i.coupon !== null) {
          dispatch(deleteCartItem(index));
        }
      })
    }
    dispatch(currentCoupon(null));
    alert.info("Coupon Removed !");
  }

  useEffect(() => {
    if (state.currentCoupon) {
      if (state.currentCoupon.discount_type === "percentage") {
        let md = 0;
        if (amount >= state.currentCoupon.min_cart_value) {
          md = amount * state.currentCoupon.discount_value * 0.01;
          if (state.currentCoupon.max_discount_valid && (md > state.currentCoupon.max_discount_value)) {
            md = state.currentCoupon.max_discount_value;
          }
        }
        setMoreDiscount(md);
      }
      else if (state.currentCoupon.discount_type === "flat") {
        let md = 0;
        if (amount >= state.currentCoupon.min_cart_value) {
          md = state.currentCoupon.discount_value;
          if (state.currentCoupon.max_discount_valid && (md > state.currentCoupon.max_discount_value)) {
            md = state.currentCoupon.max_discount_value;
          }
        }
        setMoreDiscount(md)
      }
    }
  }, [state.currentCoupon, amount])

  useEffect(() => {
    if (state.customerDetails !== null) {
      if (state.currentCoupon) {
        if (state.currentCoupon.memb_apply) {
          let mdis = 0;
          // setMD((state.customerDetails.customer_role.disc_percent * 0.01 * amount).toFixed(2));
          const mem_b_discount = state.customerDetails?.customer_role?.barista_disc_percent || 0;
          const mem_k_discount = state.customerDetails?.customer_role?.kitchen_disc_percent || 0;
          state.cartItems.forEach(i => {
            if (i.product.product_list.product_type === "Barista") {
              mdis += (mem_b_discount * 0.01 * i.amount);
            }
            else {
              mdis += (mem_k_discount * 0.01 * i.amount);
            }
          });
          setMD(mdis);
        }
        else {
          setMD(0);
        }
      }
      else {
        let mdis = 0;
        // setMD((state.customerDetails.customer_role.disc_percent * 0.01 * amount).toFixed(2));
        const mem_b_discount = state.customerDetails?.customer_role?.barista_disc_percent || 0;
        const mem_k_discount = state.customerDetails?.customer_role?.kitchen_disc_percent || 0;
        state.cartItems.forEach(i => {
          if (i.product.product_list.product_type === "Barista") {
            mdis += (mem_b_discount * 0.01 * i.amount);
          }
          else {
            mdis += (mem_k_discount * 0.01 * i.amount);
          }
        });
        setMD(mdis);
      }
    }
  }, [state.customerDetails, amount, state.currentCoupon])

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  useEffect(() => {
    if (state.customerDetails?.wallet_balance < total) setPaymentMethod("online");
    else setPaymentMethod("wallet")
  }, [state.customerDetails, total])


  // async function displayRazorpay() {
  //   let checkoutPickUpTime = state.pickUpTime.split("*");
  //   const res = await loadScript(
  //     "https://checkout.razorpay.com/v1/checkout.js"
  //   );

  //   if (!res) {
  //     alert("Razorpay SDK failed to load. Are you online?");
  //     setLoading(false);
  //     return;
  //   }

  //   let cartItems = [];

  //   state.cartItems.forEach(product => {
  //     let p = {
  //       product_id: product.product.product_id,
  //       product_name: product.product_name,
  //       quantity: product.quantity,
  //       price: product.amount,
  //       add_ons: product.addOns,
  //       discount: 0,
  //       product_type: product.product.product_list.product_type,
  //       food_type: product.product.product_list.food_type,
  //       comment: product.note,
  //       order_sku: product.product.product_list.sku
  //     };
  //     cartItems = [...cartItems, p];
  //   });
  //   const options = {
  //     key: "rzp_test_38Sa2k2XFYdME0", // Enter the Key ID generated from the Dashboard
  //     amount: (total * 100).toFixed(0),
  //     currency: "INR",
  //     name: "Heebee",
  //     description: "Test Transaction",
  //     image: { Logo },
  //     handler: async function (response) {
  //       const paymentId = response.razorpay_payment_id;
  //       const order = {
  //         "customer_no": state.customerDetails.mobile_no,
  //         "branch_id": state.currentBranch.branch_id,
  //         "branch_name": state.currentBranch.branch_name,
  //         "paid_price": Number(total),
  //         "sub_total": Number(amount),
  //         "discount": (Number(moreDiscount) + Number(member_discount)),
  //         "applied_coupons": {
  //           "coupon": (disc_coupon !== null && disc_coupon.title)
  //         },
  //         "status": "Preparing",
  //         "paid": true,
  //         "cgst": Number(CGST),
  //         "sgst": Number(SGST),
  //         "payment_method": "razorpay",
  //         "payment_id": paymentId,
  //         "address": state.currentBranch.branch_name,
  //         "order_type": "pick-up",
  //         "pick_date": checkoutPickUpTime[0],
  //         "pick_time": checkoutPickUpTime[1],
  //         "order_items": cartItems
  //       };
  //       axios.post(`${REACT_APP_API_ENDPOINT}/checkout_order`, order, {
  //         headers: {
  //           token: state.token
  //         }
  //       })
  //         .then(response => {
  //           if (response.data.status === "success") {
  //             console.log(response.data.data);
  //             window.localStorage.setItem('heebee-cart-items', JSON.stringify([]));
  //             dispatch(setCartItems([]));
  //             dispatch(fetchAllOrders(state.token, state.customerDetails.mobile_no));
  //             navigate('/my-orders');
  //           }
  //           else {
  //             alert.error("Payment unsuccessful 1 !");
  //             setLoading(false);
  //           }
  //         })
  //         .catch(err => {
  //           alert.error("Payment unsuccessful 2 !");
  //           console.log(err);
  //           setLoading(false);
  //         });
  //     },
  //     prefill: {
  //       name: `${state.customerDetails.first_name} ${state.customerDetails.last_name}`,
  //       email: state.customerDetails.email,
  //       contact: state.customerDetails.mobile_no,
  //     },
  //     notes: {
  //       address: state.currentBranch.branch_name,
  //     },
  //     theme: {
  //       color: "#ffd490",
  //     },
  //   };

  //   const paymentObject = new window.Razorpay(options);
  //   paymentObject.open();
  // }

  /* const pay = (amt) => {
    const body = {
      amount: Number(amt),
      branch_id: state.currentBranch.branch_id,
      branch_name: state.currentBranch.branch_name
    };
    const token = state.token;
    makePayment(body, token);
  }
 */

  const checkOut = () => {
    if (state.pickUpTime === null) alert.error("Please Select Pick-Up Time");
    else {
      let cartItems = [];

      state.cartItems.forEach(product => {
        let p = {
          product_id: product.product.product_id,
          product_name: product.product_name,
          quantity: product.quantity,
          price: product.amount,
          add_ons: product.addOns,
          discount: 0,
          product_type: product.product.product_list.product_type,
          food_type: product.product.product_list.food_type,
          comment: product.note,
          order_sku: product.product.product_list.sku
        };
        cartItems = [...cartItems, p];
      });

      let order = {
        customer_no: state.customerDetails.mobile_no,
        branch_id: state.currentBranch.branch_id,
        branch_name: state.currentBranch.branch_name,
        paid_price: Number(total),
        sub_total: Number(amount),
        address: state.currentBranch.branch_name,
        discount: Number(moreDiscount),
        applied_coupons: {
          coupon: state.currentCoupon
        },
        comment: "",
        tax: Number(CGST) + Number(SGST),
        payment_method: paymentMethod,
        membership_discount: Number(member_discount),
        pick_date: state.pickUpTime,
        pick_time: state.pickUpTime,
        order_items: cartItems
      };

      if (paymentMethod === "wallet") {
        setLoading(true);
        axios.post(`${REACT_APP_API_ENDPOINT}/checkout_order`, order, {
          headers: {
            token: state.token
          }
        })
          .then(response => {
            if (response.data.status === "success") {
              window.localStorage.setItem('heebee-cart-items', JSON.stringify([]));
              dispatch(setCartItems([]));
              dispatch(currentCoupon(null));
              dispatch(setCustomerDetails(state.token));
              dispatch(fetchAllOrders(state.token, state.customerDetails.mobile_no));
              navigate('/my-orders');
            }
            else {
              alert.error("Payment unsuccessful 1 !");
              setLoading(false);
            }
          })
          .catch(err => {
            alert.error("Payment unsuccessful 2 !");
            console.log(err);
            setLoading(false);
          });
      }
      else {
        setLoading(true);
        axios.post(`${REACT_APP_API_ENDPOINT}/checkout_order`, order, {
          headers: {
            token: state.token
          }
        })
          .then(response => {
            console.log(response.data)
            checkoutPaytm(response.data);

            return;
          })
          .catch(err => {
            return;
          });
        //pay(order.paid_price);
      }
    }
  }


  return (
    <>
      <div className=" flex flex-col basis-2/5 bg-primary-light-color px-4 py-4 m-auto border-radius-5 h-fit animate__animated animate__fadeInRight sidediv w-[95%] max-w-[350px] sm:w-[400px] sm:max-w-full">
        <div className="flex flex-row w-full justify-between text-primary-dark-color text-base mbtb:text-lg md:text-base lg:text-lg font-roboto">
          <div className="flex flex-col font-bold tracking-wide space-y-1">
            <div>Sub Total</div>
            <div>Member Discount</div>
            <div>Coupon Discount</div>
            <div>SGST</div>
            <div>CGST</div>
            <div>Grand Total</div>
            <div>Payment Method</div>
            {state.currentCoupon !== null && <div className="text-primary-dark-color py-1">Coupon Applied :</div>}
          </div>
          <div className="flex flex-col text-right space-y-1">
            <div>₹ {amount}</div>
            <div>₹ {member_discount}</div>
            {
              state.currentCoupon !== null ?
                <div>₹ {moreDiscount}</div>
                :
                <div className="text-light-color bg-primary-dark-color flex justify-cente items-center text-xs py-[3px] px-[8px] cursor-pointer w-fit ml-auto hover:opacity-[0.9] border-radius-5" onClick={() => {
                  navigate('/offers')
                }}>
                  Apply Coupon
                </div>
            }
            <div>₹ {SGST}</div>
            <div>₹ {CGST}</div>
            <div>₹ {total}</div>
            <div className="flex justify-end space-x-2">
              <div className={`bg-primary-dark-color opacity-95 text-light-color px-2 border-[4px] rounded-sm cursor-pointer ${paymentMethod === "wallet" ? "selected-button" : "border-transparent hover:opacity-100 scale-[0.9]"}`} onClick={() => {
                if (state.customerDetails?.wallet_balance > total)
                  setPaymentMethod("wallet")
                else alert.error("Insufficient Wallet Balance");
              }}>Wallet</div>
              <div className={`bg-primary-dark-color opacity-95 text-light-color px-2 border-[4px] rounded-sm cursor-pointer ${paymentMethod === "online" ? "selected-button" : "border-transparent hover:opacity-100 scale-[0.9]"}`} onClick={() => setPaymentMethod("online")}>Paytm</div>
            </div>
            {state.currentCoupon !== null &&
              <div className="flex justify-end">
                <div>
                  {state.currentCoupon.title}
                </div>
                <div onClick={() => {
                  deleteCoupon();
                  setMoreDiscount(0);
                }} className="bg-red-900 text-white px-[7px] ml-3 cursor-pointer rounded-xl">x</div>
              </div>}
          </div>
        </div>
        <div
          className="w-full py-2 px-6 mt-4 cursor-pointer bg-primary-dark-color hover:opacity-[0.9] text-center text-light-color text-xl font-bold tracking-wider border-radius-5 font-roboto"
          onClick={() => {
            checkOut()
          }}
        >
          Checkout
        </div>
      </div>
      {/* <Transition appear show={isModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10 border-radius-5" onClose={closeDiscountModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 border-radius-5 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto text-dark-color border-radius-5">
            <div className="flex min-h-full items-center justify-center text-center border-radius-5">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-[90%] max-w-[600px] overflow-hidden p-1 transform bg-product transition-all border-radius-5">
                  <div className=" w-[100%] max-h-[400px] flex-col space-y-1 overflow-y-scroll border-radius-5">
                    {state.coupons.length > 0 ? state.coupons.map(coupon => <div key={coupon.coupon_id} className="w-full p-[10px] bg-primary-light-color flex border-radius-5">
                      <div className="w-[80%] flex justify-center items-start flex-col space-y-1 pr-[20px] ">
                        <div className="text-sm mbtb:text-base md:text-xl uppercase text-left text-primary-dark-color">{coupon.title}</div>
                        <div className="text-[8px] mbtb:text-[10px] uppercase text-grey-500 text-left">{coupon.disc_percent !== null ? coupon.disc_percent + '%' : '₹' + coupon.flat_discount} discount on all orders{coupon.min_cart !== null ? ' above ₹ ' + coupon.min_cart + '/-' : '.'}</div>
                      </div>
                      <div className="w-[20%] flex justify-end items-center">
                        <div className={`border-radius-5 text-[14px] font-semibold px-[12px] py-[4px] cursor-pointer ${amount < coupon.min_cart ? 'bg-grey-500 text-black' : 'text-light-color bg-primary-dark-color'}`} onClick={() => amount > coupon.min_cart && applyDisocunt(
                          coupon.disc_percent !== null ? "percent" : "flat",
                          coupon.disc_percent !== null ? coupon.disc_percent : coupon.flat_discount,
                          coupon
                        )}>Apply</div>
                      </div>
                    </div>) : <div className="p-2 text-xl uppercase tracking-wide font-bold">No Coupon Found</div>}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition> */}
    </>
  );
};

export default CheckoutPageRightSection;
