import {
  ShoppingBagIcon,
  UserCircleIcon,
  MenuAlt4Icon,
  DocumentTextIcon,
  UserIcon,
  LogoutIcon,
  SunIcon,
  MoonIcon
} from "@heroicons/react/outline";
import React, { useState, Fragment, useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import CartSidebar from "./CartSidebar";
import NavSidebar from "./NavSidebar";
import { Menu, Dialog, Transition } from "@headlessui/react";
import { useSelector, useDispatch } from 'react-redux';
import { getPromoCode, logout, setTheme } from "../../actions";
import { IoWalletOutline } from "react-icons/io5";
import makePayment from "../../fntendpaytm";
import { useRef } from "react";
import AddCredit from "./AddCredit";
import { MdOutlineLocalOffer } from "react-icons/md";


const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const state = useSelector(state => state);
  const [isOpenMainSidebar, setIsOpenMainSidebar] = useState(false);
  const [isOpenCartSidebar, setIsOpenCartSidebar] = useState(false);
  const [theme, setThemeL] = useState('dark');
  const [cartNo, setCartNo] = useState(0);
  const [isAddMoneyOpen, setIsAddMoneyOpen] = useState(false);


  useEffect(
    () => {
      let userTheme = window.localStorage.getItem("heebee-cafe-website-theme");
      setThemeL(userTheme);
      document.querySelector('html').setAttribute("data-theme", userTheme);
      dispatch(setTheme(userTheme));
    }, [theme, dispatch]);

  const ToggleMainSidebar = () => {
    isOpenMainSidebar === true
      ? setIsOpenMainSidebar(false)
      : setIsOpenMainSidebar(true);
  };

  const darkModeSwitch = () => {
    if (theme === 'light') {
      document.querySelector('html').setAttribute("data-theme", "dark");
      setThemeL("dark");
      window.localStorage.setItem("heebee-cafe-website-theme", 'dark');
    }
    else {
      document.querySelector('html').setAttribute("data-theme", "light");
      setThemeL("light");
      window.localStorage.setItem("heebee-cafe-website-theme", 'light');
    }
  }

  const customerLogout = () => {
    dispatch(logout());
    navigate('/login');
  }

  useEffect(() => {
    setCartNo(state.cartItems.length);
  }, [state.cartItems]);

  const [bgColor, setBgColor] = useState("var(--primaryLightColor)");


  return (
    <>
      <div
        className="w-full p-4 navbar animate__animated animate__fadeInDown z-10 shadow-md"
        style={{ backgroundColor: bgColor }}
      >
        <div className="flex justify-between items-center md:container md:mx-auto">
          <div className="w-2/5 flex justify-start">
            <div className=" hidden md:block">
              <ul className="flex gap-1 uppercase font-roboto">
                <li>
                  <NavLink
                    to="/"
                    className={({ isActive }) =>
                      isActive ? "navlink navlink-active" : "navlink"
                    }
                  >
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/menu"
                    className={({ isActive }) =>
                      isActive ? "navlink navlink-active" : "navlink"
                    }
                  >
                    Menu
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/about"
                    className={({ isActive }) =>
                      isActive ? "navlink navlink-active" : "navlink"
                    }
                  >
                    About
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/contact"
                    className={({ isActive }) =>
                      isActive ? "navlink navlink-active" : "navlink"
                    }
                  >
                    Contact
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/career"
                    className={({ isActive }) =>
                      isActive ? "navlink navlink-active" : "navlink"
                    }
                  >
                    Career
                  </NavLink>
                </li>
              </ul>
            </div>

            <button
              className="p-2 cursor-pointer block md:hidden border-radius-5"
              style={{
                color: "var(--primaryDarkColor)",
                background: "var(--primaryLightColor)",
              }}
              onClick={ToggleMainSidebar}
            >
              <MenuAlt4Icon className="w-6 h-6" />
            </button>
            <button
              className="p-2 cursor-pointer border-radius-5 md:hidden"
              style={{
                color: "var(--primaryDarkColor)",
                background: "var(--primaryLightColor)"
              }}
              onClick={() => {

                navigate("/offers");
              }}
            >
              <MdOutlineLocalOffer className="w-6 h-6" />
            </button>
          </div>
          <div className="flex justify-center w-1/5 shadow-md">
            <Link to="/" className="absolute top-[20px] mr-[40px] mbtb:mr-[0px] scale-[0.75] sm:scale-[1]">

              <img
                src={Logo}
                alt="heebee"
                className="h-[70px] rounded-full w-[70px] drop-shadow-b-lg"
                style={{
                  borderColor: "var(--bgColor)",
                  backgroundColor: "var(--bgColor)",
                }} />
              {/* <div className="hexagon-logo overflow-hidden">
                <img src={Logo} alt="heebee" className="w-[100%] drop-shadow-b-lg" />
              </div> */}
            </Link>
          </div>
          <div className="w-2/5 flex justify-end">
            <ul className="flex gap-3">
              <li
                className="p-2 cursor-pointer relative border-radius-5"
                style={{
                  color: "var(--primaryDarkColor)",
                  background: "var(--primaryLightColor)"
                }}
                onClick={() => {
                  navigate("/cart");
                }}
              >
                <ShoppingBagIcon className="w-6 h-6" />
                <span
                  className="font-bold text-xs p-1 absolute md:top-[10.5px] top-[12px] left-[12.5px]"
                  style={{ color: "var(--primaryDarkColor)" }}
                >
                  {cartNo}
                </span>

              </li>
              <li
                className="p-2 cursor-pointer relative border-radius-5 hidden md:block"
                style={{
                  color: "var(--primaryDarkColor)",
                  background: "var(--primaryLightColor)"
                }}
                onClick={() => {
                  if (state.token === null) navigate('/login');
                  else navigate("/offers");
                }}
              >
                <MdOutlineLocalOffer className="w-6 h-6" />
              </li>

              {(state.token === null) ?
                <li className='p-2 cursor-pointer border-radius-5' style={{ color: "var(--primaryDarkColor)", background: "var(--primaryLightColor)" }} onClick={() => { navigate('/login') }} >
                  <UserCircleIcon className='w-6 h-6' />
                </li> :
                <li className="border-radius-5">
                  <Menu as="div" className="relative inline-block text-left">
                    <div>
                      <Menu.Button
                        className="p-2 cursor-pointer shadow-none border-radius-5"
                        style={{
                          color: "var(--primaryDarkColor)",
                          background: "var(--primaryLightColor)"
                        }}
                      >
                        <UserCircleIcon
                          className="w-6 h-6"
                          style={{ color: "var(--primaryDarkColor)" }}
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items
                        className="absolute right-0 mt-2 w-[150px] origin-top-right divide-y  focus:outline-none border-radius-5 shadow-lg "
                        style={{ background: "var(--primaryLightColor)" }}
                      >
                        <div className="px-1 py-2 ">
                          <div className="px-1 py-3 text-center font-semibold border-radius-5 text-black/50 flex flex-col justify-center items-center bg-white/30">
                            <IoWalletOutline
                              className="h-5 w-5 text-black/50"
                            /> <span className="text-3xl">₹{state.customerDetails?.wallet_balance}</span>
                          </div>
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                className={`${active
                                  ? "bg-primary-color text-light-color border-radius-5"
                                  : "text-primary-dark-color"
                                  } group flex w-full items-center font-bold px-2 py-2 text-sm font-roboto`}
                                onClick={() => setIsAddMoneyOpen(true)}
                              >
                                <IoWalletOutline
                                  className="mr-2 h-5 w-5"
                                />
                                Add Money
                              </button>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                className={`${active
                                  ? "bg-primary-color text-light-color border-radius-5"
                                  : "text-primary-dark-color"
                                  } group flex w-full items-center font-bold px-2 py-2 text-sm font-roboto`}
                                onClick={() => {
                                  navigate("/my-profile");
                                }}
                              >
                                <UserIcon
                                  className="mr-2 h-5 w-5"
                                  aria-hidden="true"
                                />
                                My Profile
                              </button>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                className={`${active
                                  ? "bg-primary-color text-light-color border-radius-5"
                                  : "text-primary-dark-color"
                                  } group flex w-full items-center font-bold px-2 py-2 text-sm font-roboto`}
                                onClick={() => {
                                  navigate("/my-orders");
                                }}
                              >
                                <DocumentTextIcon
                                  className="mr-2 h-5 w-5"
                                  aria-hidden="true"
                                />
                                My Orders
                              </button>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                className={`${active
                                  ? "bg-primary-color text-light-color border-radius-5"
                                  : "text-primary-dark-color"
                                  } group flex w-full items-center font-bold px-2 py-2 text-sm font-roboto`}
                                onClick={() => {
                                  customerLogout();
                                }}
                              >
                                <LogoutIcon
                                  className="mr-2 h-5 w-5"
                                  aria-hidden="true"
                                />
                                Log Out
                              </button>
                            )}
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </li>
              }
              {/* {(state.token !== null) &&
                <li className="border-radius-5">
                  <Menu as="div" className="relative inline-block text-left">
                    <div>
                      <Menu.Button
                        className="p-2 cursor-pointer shadow-none border-radius-5"
                        style={{
                          color: "var(--primaryDarkColor)",
                          background: "var(--primaryLightColor)"
                        }}
                      >
                        <IoWalletOutline
                          className="w-6 h-6"
                          style={{ color: "var(--primaryDarkColor)" }}
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items
                        className="absolute right-0 mt-2 w-[130px] origin-top-right divide-y  focus:outline-none shadow-lg border-radius-5  "
                        style={{ background: "var(--primaryLightColor)" }}
                      >
                        <div className="px-1 py-1 text-center font-semibold border-radius-5 text-primary-dark-color">
                          ₹ {state.customerDetails?.wallet_balance}
                        </div>
                        <div className="px-1 py-1 text-center font-semibold cursor-pointer" onClick={() => setIsAddMoneyOpen(true)}>
                          Add Money
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </li>
              } */}

              <li
                className="p-2 cursor-pointer relative border-radius-5"
                style={{
                  color: "var(--primaryDarkColor)",
                  background: "var(--primaryLightColor)"
                }}
                onClick={() => {
                  darkModeSwitch();
                }}
              >
                {theme === 'dark' ?
                  <SunIcon
                    className="w-6 h-6"
                    style={{ color: "var(--primaryDarkColor)" }}
                  /> :
                  <MoonIcon
                    className="w-6 h-6"
                    style={{ color: "var(--primaryDarkColor)" }}
                  />}

              </li>
            </ul>
          </div>
        </div>
      </div>

      <AddCredit
        isAddMoneyOpen={isAddMoneyOpen}
        setIsAddMoneyOpen={setIsAddMoneyOpen}
      />

      <NavSidebar
        isOpenMainSidebar={isOpenMainSidebar}
        setIsOpenMainSidebar={setIsOpenMainSidebar}
      />
      <CartSidebar
        isOpenCartSidebar={isOpenCartSidebar}
        setIsOpenCartSidebar={setIsOpenCartSidebar}
      />
    </>
  );
};

export default Header;
